.icon.menu .listing-menu {
  position: absolute;
  z-index: 100;
  right: -0.1em;
  top: 2.25em;
  width: 300px;
  box-shadow: 0 3px 6px -3px rgba(23, 24, 24, 0.08), 0 8px 20px -4px rgba(23, 24, 24, 0.12);
  background-color: #fff;
}
.icon.menu .listing-menu .company-name {
  padding: 0.5em;
  display: block;
}
.icon.menu .listing-menu ul.td-list {
  width: 100%;
}
.icon.menu .listing-menu ul.td-list li.spacer {
  border-bottom: 1px solid #caced3;
}
.icon.menu .listing-menu ul.td-list li {
  list-style: none;
  width: 100%;
}
.icon.menu .listing-menu ul.td-list li a {
  display: inline-block;
  width: 100%;
  padding: 0.25em 0.5em;
  color: #454545;
  text-decoration: none;
}
.icon.menu .listing-menu ul.td-list li a:hover {
  color: #f87d20;
  font-weight: normal;
}
/* colors */
/* Fonts */
/* Display breakpoints */
/* most of the modern smartphones have dimesions >= 375px*/
/* older smartphones like iPhone 4, iPhone 5, etc.*/
/* Shadows */
/* Shapes */
