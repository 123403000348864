/* critical:start */
.cmp-listing {
  width: 100%;
  margin: 1em 0;
  background-color: #fff;
  text-align: left;
}
.cmp-listing .promo-banner {
  color: #fff;
  background-color: #e35843;
  padding: 0.35em 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  flex-wrap: wrap;
}
.cmp-listing .promo-banner h6 {
  color: #fff;
  margin: 0;
}
.cmp-listing .promo-banner button.slim {
  color: #e35843;
  padding: 0.25em 0.5em;
}
.cmp-listing .header {
  display: grid;
  grid-template-columns: auto max-content min-content;
  align-items: center;
  grid-column-gap: 0.5em;
  padding: 0.5em;
  position: relative;
}
.cmp-listing .header a.title {
  display: flex;
}
.cmp-listing .header a.title svg {
  margin-right: 0.35em;
}
.cmp-listing .header span.name {
  margin-right: 0.5em;
  white-space: nowrap;
}
.cmp-listing .header .icon.type {
  width: 1em;
  height: 1em;
  min-width: 1em;
  display: inline-block;
  margin-right: 0.35em;
}
.cmp-listing .body {
  padding: 0.5em;
  grid-row-gap: 0.75em;
  display: grid;
  grid-column-gap: 0.5em;
  grid-template-columns: 100%;
  grid-template-areas: 'address' 'links' 'phonenumbers' 'tagline' 'highlights';
  align-items: start;
}
.cmp-listing .body .logo {
  grid-area: logo;
}
.cmp-listing .body .address {
  grid-area: address;
  line-height: 1.5em;
}
.cmp-listing .body .cmp-listing-links {
  grid-area: links;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.75em;
  row-gap: 0.75em;
  margin-right: 1em;
}
.cmp-listing .body .cmp-listing-links a.td-badge {
  padding: 0.35em 0.75em;
}
.cmp-listing .body .phone-numbers {
  grid-area: phonenumbers;
  line-height: 1.25em;
  justify-self: end;
}
.cmp-listing .body .phone-numbers .labels {
  padding-right: 0.35em;
}
.cmp-listing .body .phone-numbers .numbers {
  display: inline-block;
}
.cmp-listing .body .tagline {
  grid-area: tagline;
  padding-top: 0.5em;
}
.cmp-listing .body .highlights {
  grid-area: highlights;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.75em;
  line-height: 1.5em;
}
.cmp-listing .body .highlights .highlight svg {
  margin-right: 0.25em;
}
@media screen and (min-width: 960px) {
  .cmp-listing .body {
    grid-template-columns: calc(50% + 58px) auto calc(50% - 232px);
    grid-column-gap: 0;
    grid-template-areas: 'address blank1 phonenumbers' 'links links blank2' 'tagline tagline tagline' 'highlights highlights highlights';
  }
  .cmp-listing .body .links {
    margin-top: 0.25em;
  }
}
.cmp-listing .body .highlights svg {
  color: #454545;
}
.cmp-listing .footer {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: repeat(5, auto);
  grid-column-gap: 0.35em;
  grid-template-rows: auto auto;
  padding: 0.5em;
}
.cmp-listing .footer button {
  font-size: 0.88em;
  padding: 0.5em 0.9em;
  text-transform: uppercase;
}
.cmp-listing .footer button {
  color: #807f83;
  border: 0.094em solid #807f83;
  background-color: #fff;
}
.cmp-listing .footer button:hover {
  color: #fff;
  background-color: #454545;
  border: 0.094em solid #454545;
  filter: brightness(1.1);
}
.cmp-listing .footer button:active,
.cmp-listing .footer button.active {
  color: #fff;
  background-color: #454545;
  border: 0.094em solid #454545;
}
@media only screen and (max-width: 1399px) {
  .cmp-listing .footer button {
    font-size: 0.85em;
  }
}
@media only screen and (max-width: 959px) {
  .cmp-listing .body .phone-numbers {
    justify-self: start;
  }
  .cmp-listing .body .phone-numbers .labels {
    text-align: left;
  }
  .cmp-listing .body .phone-numbers button {
    font-size: 0.9em;
    padding: 0.5em 1em;
  }
  .cmp-listing .footer button {
    padding: 0.5em;
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 750px) {
  .cmp-listing .footer button {
    display: flex;
    flex-direction: column;
    grid-row-gap: 0.35em;
    line-height: 1.1em;
    font-size: 0.82em;
    text-transform: capitalize;
    padding: 0.45em;
    height: 52px;
  }
}
@media only screen and (max-width: 560px) {
  .cmp-listing .header .title {
    max-width: 260px;
    overflow-y: auto;
    text-overflow: clip;
  }
  .cmp-listing .body .cmp-listing-links {
    font-size: 0.83em;
  }
  .cmp-listing .footer button {
    height: 62px;
    line-height: 0.93em;
    font-size: 0.76em;
    max-width: 58px;
    text-transform: none;
  }
}
@media only screen and (min-width: 0) and (max-width: 374px) {
  .cmp-listing .footer button {
    font-size: 0.7em;
    max-width: 52px;
    padding: 0.3em;
  }
}
.cmp-listing.stock .body {
  grid-template-columns: 100%;
}
@media screen and (min-width: 380px) {
  .cmp-listing.stock .body .links,
  .cmp-listing.stock .body .address,
  .cmp-listing.stock .body .phone-numbers {
    margin-left: 0.5em;
  }
  .cmp-listing.stock .body .phone-numbers .numbers {
    padding-left: 0;
  }
}
@media screen and (min-width: 960px) {
  .cmp-listing.stock .body {
    grid-template-columns: calc(50% + 58px) auto calc(50% - 232px);
    grid-column-gap: 0;
    grid-template-areas: 'address blank1 phonenumbers' 'links links blank2' 'tagline tagline tagline' 'highlights highlights highlights';
  }
  .cmp-listing.stock .body .links {
    margin-top: 0.25em;
  }
}
.cmp-listing.premium .body {
  grid-template-columns: 100%;
  grid-template-areas: 'logo' 'address' 'links' 'phonenumbers' 'tagline' 'highlights';
}
.cmp-listing.premium .body div.logo {
  width: 110px;
  height: 110px;
}
.cmp-listing.premium .body div.logo a img {
  width: 108px;
  height: auto;
  max-height: 108px;
}
@media screen and (min-width: 380px) {
  .cmp-listing.premium .body {
    grid-template-columns: 110px calc(100% - 110px);
    grid-template-areas: 'logo address' 'logo links' 'logo phonenumbers' 'tagline tagline' 'highlights highlights';
  }
}
@media screen and (min-width: 960px) {
  .cmp-listing.premium .body {
    grid-template-columns: 210px calc(50% - 132px) auto calc(50% - 232px);
    grid-template-areas: 'logo address blank1 phonenumbers' 'logo links links blank2' 'logo tagline tagline tagline' 'logo highlights highlights highlights';
  }
  .cmp-listing.premium .body div.logo {
    width: 178px;
    height: 178px;
  }
  .cmp-listing.premium .body div.logo a img {
    width: 176px;
    height: auto;
    max-height: 176px;
  }
}
.cmp-listing .footer .btn-double {
  display: inline-flex;
}
.cmp-listing .footer button.td-btn .icon {
  position: relative;
  margin-right: 0.75em;
}
.cmp-listing .footer button.td-btn .icon .td-circle-badge {
  border-radius: 1.8em;
  padding: 0.25em 0.56em;
  font-size: 0.6em;
  position: absolute;
  top: -5px;
  margin-left: 0.15em;
}
.cmp-listing .details {
  padding: 1em 0.75em;
  display: grid;
  grid-template-columns: 55% 43%;
  column-gap: 1em;
}
.cmp-listing .details .description,
.cmp-listing .details .services,
.cmp-listing .details .dealers,
.cmp-listing .details .column-right,
.cmp-listing .details .hours-container {
  margin-bottom: 1em;
}
@media only screen and (max-width: 959px) {
  .cmp-listing .details {
    grid-template-columns: 100%;
  }
  .cmp-listing .details li.service .item {
    padding: 0.25em 0;
  }
}
.cmp-listing .details h4,
.cmp-listing .details h3 {
  font-size: 1.15em;
  padding-bottom: 0.5em;
  text-transform: uppercase;
}
.cmp-listing .details .td-list svg {
  margin-right: 0.25em;
}
.cmp-listing .details li.service {
  line-height: 1.35em;
}
.cmp-listing .details li.service ul {
  margin: 0.1em 0 0 2.5em;
}
@media only screen and (min-width: 720px) and (max-width: 959px) {
  .cmp-listing .details .hours-payment {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
  }
}
@media only screen and (max-width: 719px) {
  .cmp-listing .details .hours-payment {
    grid-template-columns: 1fr;
  }
}
.cmp-listing .details .hours-container .td-badge {
  display: block;
  margin: 0 0 0.75em 0;
}
.cmp-listing .details .hours-container .time {
  padding: 0.5em 0;
  display: flex;
  column-gap: 0.75em;
}
.cmp-listing .details .hours-container .time .message {
  font-size: 0.85em;
}
.cmp-listing .details .payment_types img {
  margin-right: 0.5em;
}
/* critical:end */
.a.td-btn {
  display: inline-block;
}
.cmp-hours-status span.icon.closed svg {
  color: #E35843;
}
.cmp-hours-status span.icon.open svg {
  color: #228D00;
}
.cmp-listing {
  color: #454545;
  box-shadow: 0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.1), 0rem 0.0625rem 0.375rem rgba(31, 33, 36, 0.05);
  border-radius: 4px;
  /* Transitions on expand */
  /* End of transitions on expand */
}
.cmp-listing .header {
  background-color: #c5c5c5;
  border: solid 1px #c5c5c5;
}
.cmp-listing .header a,
.cmp-listing .header svg,
.cmp-listing .header span.title {
  color: #454545;
}
.cmp-listing .header a:hover,
.cmp-listing .header svg:hover {
  color: #454545;
  font-weight: bold;
}
.cmp-listing .header .icon.preferred {
  color: #f87d20;
}
.cmp-listing .header {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.cmp-listing .header a,
.cmp-listing .header svg {
  cursor: pointer;
}
.cmp-listing .body .cmp-listing-links a {
  box-shadow: 0 0 5px rgba(23, 24, 24, 0.05), 0 1px 2px rgba(0, 0, 0, 0.15);
}
.cmp-listing .body .cmp-listing-links a:hover {
  background-color: #dbd9d9;
}
.cmp-listing .body .phone-numbers .labels {
  font-weight: bold;
  display: inline-block;
  text-align: right;
}
.cmp-listing .body .tagline {
  font-style: italic;
}
.cmp-listing.preferred .header {
  background-color: #f87d20;
  border: solid 1px #f87d20;
}
.cmp-listing.preferred .header a,
.cmp-listing.preferred .header svg,
.cmp-listing.preferred .header span.title {
  color: #fff;
}
.cmp-listing.preferred .header a:hover,
.cmp-listing.preferred .header svg:hover {
  color: #fff;
  font-weight: bold;
}
.cmp-listing.preferred .header .icon.preferred {
  color: #5ab7e8;
}
.cmp-listing.preferred .body .highlights svg {
  color: #f87d20;
}
.cmp-listing.preferred .footer button {
  color: #f87d20;
  border: 0.094em solid #f87d20;
  background-color: #fff;
}
.cmp-listing.preferred .footer button:hover {
  color: #fff;
  background-color: #f87d20;
  border: 0.094em solid #f87d20;
  filter: brightness(1.1);
}
.cmp-listing.preferred .footer button:active,
.cmp-listing.preferred .footer button.active {
  color: #fff;
  background-color: #f87d20;
  border: 0.094em solid #f87d20;
}
.cmp-listing.preferred .td-list li svg {
  color: #f87d20;
}
.cmp-listing.preferred.promo {
  border: 1px solid #f87d20;
}
.cmp-listing.elite .header {
  background-color: #0a71a5;
  border: solid 1px #0a71a5;
}
.cmp-listing.elite .header a,
.cmp-listing.elite .header svg,
.cmp-listing.elite .header span.title {
  color: #fff;
}
.cmp-listing.elite .header a:hover,
.cmp-listing.elite .header svg:hover {
  color: #fff;
  font-weight: bold;
}
.cmp-listing.elite .header .icon.preferred {
  color: #f87d20;
}
.cmp-listing.elite .body .highlights svg {
  color: #0A71A5;
}
.cmp-listing.elite .footer button {
  color: #0A71A5;
  border: 0.094em solid #0A71A5;
  background-color: #f7f7f7;
}
.cmp-listing.elite .footer button:hover {
  color: #fff;
  background-color: #0A71A5;
  border: 0.094em solid #0A71A5;
  filter: brightness(1.1);
}
.cmp-listing.elite .footer button:active,
.cmp-listing.elite .footer button.active {
  color: #fff;
  background-color: #0A71A5;
  border: 0.094em solid #0A71A5;
}
.cmp-listing.elite .td-list li svg {
  color: #0A71A5;
}
.cmp-listing.elite.promo {
  border: 1px solid #0A71A5;
  background-color: #EEF5FB;
}
.cmp-listing.pro .header {
  background-color: #5ab7e8;
  border: solid 1px #5ab7e8;
}
.cmp-listing.pro .header a,
.cmp-listing.pro .header svg,
.cmp-listing.pro .header span.title {
  color: #fff;
}
.cmp-listing.pro .header a:hover,
.cmp-listing.pro .header svg:hover {
  color: #fff;
  font-weight: bold;
}
.cmp-listing.pro .header .icon.preferred {
  color: #f87d20;
}
.cmp-listing.pro .body .highlights svg {
  color: #5AB7E8;
}
.cmp-listing.pro .footer button {
  color: #4fa1cc;
  border: 0.094em solid #4fa1cc;
  background-color: #f7f7f7;
}
.cmp-listing.pro .footer button:hover {
  color: #fff;
  background-color: #55abd8;
  border: 0.094em solid #5AB7E8;
  filter: brightness(1.1);
}
.cmp-listing.pro .footer button:active,
.cmp-listing.pro .footer button.active {
  color: #fff;
  background-color: #55abd8;
  border: 0.094em solid #5AB7E8;
}
.cmp-listing.pro .td-list li svg {
  color: #5AB7E8;
}
.cmp-listing.pro.promo {
  border: 1px solid #5AB7E8;
  background-color: #EEF5FB;
}
.cmp-listing.branded .header {
  background-color: #8c3f1e;
  border: solid 1px #8c3f1e;
}
.cmp-listing.branded .header a,
.cmp-listing.branded .header svg,
.cmp-listing.branded .header span.title {
  color: #fff;
}
.cmp-listing.branded .header a:hover,
.cmp-listing.branded .header svg:hover {
  color: #fff;
  font-weight: bold;
}
.cmp-listing.branded .header .icon.preferred {
  color: #f87d20;
}
.cmp-listing.branded .body .highlights svg {
  color: #8c3f1e;
}
.cmp-listing.branded .footer button {
  color: #8c3f1e;
  border: 0.094em solid #8c3f1e;
  background-color: #f7f7f7;
}
.cmp-listing.branded .footer button:hover {
  color: #fff;
  background-color: #8c3f1e;
  border: 0.094em solid #8c3f1e;
  filter: brightness(1.1);
}
.cmp-listing.branded .footer button:active,
.cmp-listing.branded .footer button.active {
  color: #fff;
  background-color: #8c3f1e;
  border: 0.094em solid #8c3f1e;
}
.cmp-listing.branded .td-list li svg {
  color: #8c3f1e;
}
.cmp-listing.branded.promo {
  border: 1px solid #8c3f1e;
  background-color: #FFFAF7;
}
.cmp-listing.msp .header {
  background-color: #b20838;
  border: solid 1px #b20838;
}
.cmp-listing.msp .header a,
.cmp-listing.msp .header svg,
.cmp-listing.msp .header span.title {
  color: #fff;
}
.cmp-listing.msp .header a:hover,
.cmp-listing.msp .header svg:hover {
  color: #fff;
  font-weight: bold;
}
.cmp-listing.msp .header .icon.preferred {
  color: #f87d20;
}
.cmp-listing.msp .body .highlights svg {
  color: #b20838;
}
.cmp-listing.msp .footer button {
  color: #b20838;
  border: 0.094em solid #b20838;
  background-color: #f7f7f7;
}
.cmp-listing.msp .footer button:hover {
  color: #fff;
  background-color: #b20838;
  border: 0.094em solid #b20838;
  filter: brightness(1.1);
}
.cmp-listing.msp .footer button:active,
.cmp-listing.msp .footer button.active {
  color: #fff;
  background-color: #b20838;
  border: 0.094em solid #b20838;
}
.cmp-listing.msp .td-list li svg {
  color: #b20838;
}
.cmp-listing.mspar .header {
  background-color: #b20838;
  border: solid 1px #b20838;
}
.cmp-listing.mspar .header a,
.cmp-listing.mspar .header svg,
.cmp-listing.mspar .header span.title {
  color: #fff;
}
.cmp-listing.mspar .header a:hover,
.cmp-listing.mspar .header svg:hover {
  color: #fff;
  font-weight: bold;
}
.cmp-listing.mspar .header .icon.preferred {
  color: #f87d20;
}
.cmp-listing.mspar .body .highlights svg {
  color: #b20838;
}
.cmp-listing.mspar .footer button {
  color: #b20838;
  border: 0.094em solid #b20838;
  background-color: #fff;
}
.cmp-listing.mspar .footer button:hover {
  color: #fff;
  background-color: #b20838;
  border: 0.094em solid #b20838;
  filter: brightness(1.1);
}
.cmp-listing.mspar .footer button:active,
.cmp-listing.mspar .footer button.active {
  color: #fff;
  background-color: #b20838;
  border: 0.094em solid #b20838;
}
.cmp-listing.mspar .td-list li svg {
  color: #b20838;
}
.cmp-listing.premium .body .phone-numbers button {
  color: #fff;
  background-color: #f87d20;
  border: none;
}
.cmp-listing.premium .body .phone-numbers button:hover {
  background-color: #E5610C;
}
.cmp-listing.premium .body .phone-numbers button:active {
  background-color: #E5610C;
}
.cmp-listing .footer button {
  font-weight: bold;
}
.cmp-listing .footer .btn-double .favorite-btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: none;
  cursor: pointer;
}
.cmp-listing .footer .btn-double .avoid-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  cursor: pointer;
}
.cmp-listing .footer button.td-btn.disabled,
.cmp-listing .footer button.td-btn:disabled,
.cmp-listing .footer button.td-btn[disabled] {
  border: 1px solid #caced3;
  background-color: #f1f1f1;
  color: #807f83;
  opacity: 0.7;
}
.cmp-listing .footer button.td-btn.disabled:active,
.cmp-listing .footer button.td-btn:disabled:active,
.cmp-listing .footer button.td-btn[disabled]:active,
.cmp-listing .footer button.td-btn.disabled.active,
.cmp-listing .footer button.td-btn:disabled.active,
.cmp-listing .footer button.td-btn[disabled].active,
.cmp-listing .footer button.td-btn.disabled:focus,
.cmp-listing .footer button.td-btn:disabled:focus,
.cmp-listing .footer button.td-btn[disabled]:focus {
  color: #454545;
  border: 1px solid #454545;
  background-color: #f1f1f1;
}
.cmp-listing .footer button.td-btn.disabled:active:hover,
.cmp-listing .footer button.td-btn:disabled:active:hover,
.cmp-listing .footer button.td-btn[disabled]:active:hover,
.cmp-listing .footer button.td-btn.disabled.active:hover,
.cmp-listing .footer button.td-btn:disabled.active:hover,
.cmp-listing .footer button.td-btn[disabled].active:hover,
.cmp-listing .footer button.td-btn.disabled:focus:hover,
.cmp-listing .footer button.td-btn:disabled:focus:hover,
.cmp-listing .footer button.td-btn[disabled]:focus:hover {
  background-color: #f1f1f1;
}
.cmp-listing .footer button.td-btn.disabled:hover,
.cmp-listing .footer button.td-btn:disabled:hover,
.cmp-listing .footer button.td-btn[disabled]:hover {
  color: #454545;
  border: 1px solid #454545;
}
.cmp-listing .footer button.td-btn .icon .td-circle-badge {
  background-color: #f87d20;
  color: #fff;
}
.cmp-listing .details {
  background-color: #fff;
}
.cmp-listing .details h4,
.cmp-listing .details h3 {
  text-transform: uppercase;
  border-bottom: 1px solid #E1E3E5;
}
.cmp-listing .details li.service ul {
  list-style-type: circle;
  align-self: start;
}
.cmp-listing .details .hours-container .time {
  text-align: right;
  justify-content: space-between;
}
.cmp-listing .details .hours-container .time:not(:last-child) {
  border-bottom: 1px solid #E1E3E5;
}
.cmp-listing .details .hours-container .time .message {
  font-style: italic;
  color: #6D7175;
  padding-bottom: 0.1em;
}
.cmp-listing .details .hours-container .time .message.td-text-danger {
  color: #E35843;
}
.cmp-listing .details .hours-container .time .day {
  text-align: left;
}
.cmp-listing .details .hours-container .time.closed {
  color: #E35843;
}
.cmp-listing .captcha,
.cmp-listing .warning {
  color: #c55806;
  width: 300px;
  display: inline-block;
  padding-bottom: 0.5em;
}
.cmp-listing .expand-enter-active {
  transition: opacity 0.5s ease;
}
.cmp-listing .expand-enter-from {
  opacity: 0;
}
.cmp-listing .listing-map {
  height: 300px;
}
.cmp-listing .data {
  background-color: #e7e7e7;
}
.cmp-listing .data > div {
  min-height: 320px;
}
.cmp-listing .data .subheading {
  font-style: italic;
}
.cmp-listing .data .action-wrapper {
  padding: 1.25em 0.5em;
}
.cmp-listing .data .services-container {
  padding: 1em;
}
.cmp-listing .data .services-container .service-list {
  list-style-type: none;
  display: grid;
  justify-items: baseline;
  grid-template-columns: 1fr 1fr;
  padding: 0;
}
.cmp-listing .data .services-container .service-list .item ul {
  padding-left: 1.5em;
}
.cmp-listing .data .inputs-container {
  max-width: 600px;
  padding: 1em 0.5em;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
}
@media only screen and (max-width: 959px) {
  .cmp-listing .data .inputs-container {
    max-width: 100%;
  }
}
.cmp-listing .data .inputs-container input,
.cmp-listing .data .inputs-container textarea {
  margin-top: 0.5em;
}
.cmp-listing .data .inputs-container input::placeholder,
.cmp-listing .data .inputs-container textarea::placeholder {
  opacity: 0.8;
  font-size: 0.95em;
}
.cmp-listing .data .form-buttons {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 1.75em 0 1em 0;
  width: 100%;
}
@media only screen and (max-width: 959px) {
  .cmp-listing .data .form-buttons {
    padding: 1.75em 0em 1em 0em;
    max-width: 100%;
  }
}
.cmp-listing .data .form-buttons.pb-0 {
  padding-bottom: 0;
}
.planrv .cmp-listing .footer button.action .icon .badge {
  background-color: #eb5643;
}
.planrv .cmp-listing.premium .phone-numbers button {
  color: #fff;
  background-color: #eb5643;
  border: none;
}
.planrv .cmp-listing.premium .phone-numbers button:hover,
.planrv .cmp-listing.premium .phone-numbers button:active {
  background-color: #ec442e;
}
.planrv .cmp-listing.elite .header {
  background-color: #eb5643;
  border: solid 1px #eb5643;
}
.planrv .cmp-listing.elite .header a,
.planrv .cmp-listing.elite .header svg,
.planrv .cmp-listing.elite .header span.title {
  color: #fff;
}
.planrv .cmp-listing.elite .header a:hover,
.planrv .cmp-listing.elite .header svg:hover {
  color: #fff;
  font-weight: bold;
}
.planrv .cmp-listing.elite .header .icon.preferred {
  color: #eb5643;
}
.planrv .cmp-listing.elite .body .highlights svg {
  color: #eb5643;
}
.planrv .cmp-listing.elite .footer button {
  color: #eb5643;
  border: 0.094em solid #eb5643;
  background-color: #f7f7f7;
}
.planrv .cmp-listing.elite .footer button:hover {
  color: #fff;
  background-color: #eb5643;
  border: 0.094em solid #eb5643;
  filter: brightness(1.1);
}
.planrv .cmp-listing.elite .footer button:active,
.planrv .cmp-listing.elite .footer button.active {
  color: #fff;
  background-color: #eb5643;
  border: 0.094em solid #eb5643;
}
.planrv .cmp-listing.elite .td-list li svg {
  color: #eb5643;
}
.planrv .cmp-listing.pro .header {
  background-color: #739558;
  border: solid 1px #739558;
}
.planrv .cmp-listing.pro .header a,
.planrv .cmp-listing.pro .header svg,
.planrv .cmp-listing.pro .header span.title {
  color: #fff;
}
.planrv .cmp-listing.pro .header a:hover,
.planrv .cmp-listing.pro .header svg:hover {
  color: #fff;
  font-weight: bold;
}
.planrv .cmp-listing.pro .header .icon.preferred {
  color: #739558;
}
.planrv .cmp-listing.pro .body .highlights svg {
  color: #739558;
}
.planrv .cmp-listing.pro .footer button {
  color: #739558;
  border: 0.094em solid #739558;
  background-color: #f7f7f7;
}
.planrv .cmp-listing.pro .footer button:hover {
  color: #fff;
  background-color: #739558;
  border: 0.094em solid #739558;
  filter: brightness(1.1);
}
.planrv .cmp-listing.pro .footer button:active,
.planrv .cmp-listing.pro .footer button.active {
  color: #fff;
  background-color: #739558;
  border: 0.094em solid #739558;
}
.planrv .cmp-listing.pro .td-list li svg {
  color: #739558;
}
.planrv .cmp-listing.branded .header {
  background-color: #75bbbf;
  border: solid 1px #75bbbf;
}
.planrv .cmp-listing.branded .header a,
.planrv .cmp-listing.branded .header svg,
.planrv .cmp-listing.branded .header span.title {
  color: #fff;
}
.planrv .cmp-listing.branded .header a:hover,
.planrv .cmp-listing.branded .header svg:hover {
  color: #fff;
  font-weight: bold;
}
.planrv .cmp-listing.branded .header .icon.preferred {
  color: #75bbbf;
}
.planrv .cmp-listing.branded .body .highlights svg {
  color: #75bbbf;
}
.planrv .cmp-listing.branded .footer button {
  color: #75bbbf;
  border: 0.094em solid #75bbbf;
  background-color: #f7f7f7;
}
.planrv .cmp-listing.branded .footer button:hover {
  color: #fff;
  background-color: #75bbbf;
  border: 0.094em solid #75bbbf;
  filter: brightness(1.1);
}
.planrv .cmp-listing.branded .footer button:active,
.planrv .cmp-listing.branded .footer button.active {
  color: #fff;
  background-color: #75bbbf;
  border: 0.094em solid #75bbbf;
}
.planrv .cmp-listing.branded .td-list li svg {
  color: #75bbbf;
}
/* colors */
/* Fonts */
/* Display breakpoints */
/* most of the modern smartphones have dimesions >= 375px*/
/* older smartphones like iPhone 4, iPhone 5, etc.*/
/* Shadows */
/* Shapes */
