.td-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.75em;
}
.td-flex .text-normal {
  font-weight: 300;
}
