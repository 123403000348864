/* critical:start */
.images {
  position: relative;
  width: 100%;
  max-width: 390px;
  margin: 0 auto;
  padding-bottom: 1em;
}
.images.image-single {
  max-width: 600px;
}
.images.image-single .carousel-container {
  overflow: visible;
}
@media only screen and (min-width: 959px) and (max-width: 1200px) {
  .images {
    max-width: 300px;
  }
}
@media only screen and (max-width: 959px) {
  .images {
    max-width: 420px;
  }
}
@media only screen and (max-width: 525px) {
  .images {
    max-width: 270px;
  }
}
@media only screen and (min-width: 0) and (max-width: 374px) {
  .images {
    max-width: 210px;
  }
}
.images .carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.images .carousel-indicators span {
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 50%;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  background-color: #bbb;
  transition: background-color 0.3s ease;
}
.images .carousel-indicators span:hover,
.images .carousel-indicators span.active {
  background-color: #555;
}
.images .carousel-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
}
.images .carousel-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}
.images .carousel-container img.active {
  opacity: 1;
}
.images .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.75rem;
  height: 1.75rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.images .arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.images .arrow-left {
  left: -2rem;
}
.images .arrow-right {
  right: -2rem;
}
/* critical:end */
.images img.active {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.arrow {
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.arrow-left {
  left: -2rem;
}
.arrow-right {
  right: -2rem;
}
/* colors */
/* Fonts */
/* Display breakpoints */
/* most of the modern smartphones have dimesions >= 375px*/
/* older smartphones like iPhone 4, iPhone 5, etc.*/
/* Shadows */
/* Shapes */
