
.icon.menu .listing-menu {
    position: absolute;
    z-index: 100;
    right: -0.1em;
    top: 2.25em;
    width: 300px;
    box-shadow: 0 3px 6px -3px rgba(23, 24, 24, 0.08), 0 8px 20px -4px rgba(23, 24, 24, 0.12);
    background-color: #fff;

    .company-name {
        padding: 0.5em;
        display: block;
    }

    ul.td-list {
        width: 100%;
        li.spacer {
            border-bottom: 1px solid @border-color;
        }

        li {
            list-style: none;
            width: 100%;

            a {
                display: inline-block;
                width: 100%;
                padding: 0.25em 0.5em;
                color: @dark-grey;
                text-decoration: none;

                &:hover {
                    color: @orange;
                    font-weight: normal;
                }
            }
        }
    }
}
@import '@truckdown/components-systems/Styles/variables.less';