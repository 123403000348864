/* critical:start */

.cmp-signin-or-upgrade {
    background-color: #e7e7e7;
    text-align: center;
    padding: 2em;

    &.narrow {
        padding: 1em;

        .learn-more {
            margin-top: 1em;
        }
    }

    .heading {
        h2, h3, h4 {
            margin: 0 0 0.25em 0;
            text-transform: uppercase;
        }

        h3, h4 {
            font-weight: normal;
        }

        p {
            color: @text-subdued;
            font-style: italic;
            text-transform: none;
        }
    }

    .subheading {
        font-weight: normal;

        h3:first-of-type {
            text-transform: uppercase;
            color: @dark-blue;
            font-weight: normal;
            margin: 1em 0 0.5em 0;
        }

        h4, h5 {
            font-weight: normal;
            margin: 0 0 2em 0;
            font-size: 1em;
        }
    }

    a {
        display: inline-block;
    }

    .learn-more {
        font-style: italic;
        display: block;
        margin-top: 1.5em;
    }
}

/* critical:end */

@import '@truckdown/components-systems/Styles/variables.less';