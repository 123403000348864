/* critical:start */
.cmp-signin-or-upgrade {
  background-color: #e7e7e7;
  text-align: center;
  padding: 2em;
}
.cmp-signin-or-upgrade.narrow {
  padding: 1em;
}
.cmp-signin-or-upgrade.narrow .learn-more {
  margin-top: 1em;
}
.cmp-signin-or-upgrade .heading h2,
.cmp-signin-or-upgrade .heading h3,
.cmp-signin-or-upgrade .heading h4 {
  margin: 0 0 0.25em 0;
  text-transform: uppercase;
}
.cmp-signin-or-upgrade .heading h3,
.cmp-signin-or-upgrade .heading h4 {
  font-weight: normal;
}
.cmp-signin-or-upgrade .heading p {
  color: #6D7175;
  font-style: italic;
  text-transform: none;
}
.cmp-signin-or-upgrade .subheading {
  font-weight: normal;
}
.cmp-signin-or-upgrade .subheading h3:first-of-type {
  text-transform: uppercase;
  color: #0A71A5;
  font-weight: normal;
  margin: 1em 0 0.5em 0;
}
.cmp-signin-or-upgrade .subheading h4,
.cmp-signin-or-upgrade .subheading h5 {
  font-weight: normal;
  margin: 0 0 2em 0;
  font-size: 1em;
}
.cmp-signin-or-upgrade a {
  display: inline-block;
}
.cmp-signin-or-upgrade .learn-more {
  font-style: italic;
  display: block;
  margin-top: 1.5em;
}
/* critical:end */
/* colors */
/* Fonts */
/* Display breakpoints */
/* most of the modern smartphones have dimesions >= 375px*/
/* older smartphones like iPhone 4, iPhone 5, etc.*/
/* Shadows */
/* Shapes */
