/* critical:start */

/*body.v2 .mt-1 {
    margin-top: 0.5em;
}*/

.cmp-promotion {
    margin: 1em auto 1em auto;
    min-height: 150px;
    max-width: 1200px;

    @media @sm-down {
        min-height: 200px;
    }

    .content {
        padding: 1em 0.5em;
        margin-top: 1em;
        background-color: @background-green;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        border: 1.5px solid #28a745;
        border-radius: @border-radius;
    }

    .promo-listing {
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-column-gap: 1em;

        p {
            margin-bottom: 1em;
        }

        .submesssage {
            margin-bottom: 1.5em;
        }

        .address {
            align-self: start;
            text-align: right;
            color: @dark-grey;
        }

        .message {
            height: fit-content;

            a.more {
                display: inline-block;
                text-decoration: none;
                margin-left: 1em;
            }

            svg {
                color: #e35843;
                padding-right: 0.5em;
            }

            h3 {
                font-size: 1.25em;
                margin: 0 0 0.5em 0;
            }

            h4 {
                font-size: 1.2em;
                color: #e35843;
            }

            .td-btn {
                display: inline-block;
                background-color: #e35843;
                color: #fff;
            }
        }

        @media @sm-down {
            grid-template-columns: 1.7fr 1fr;
            grid-column-gap: 0.5em;

            .address {
                font-size: 0.9em;

                strong {
                    font-size: 1em;
                }
            }

            .message {
                h3, h4, p {
                    font-size: 0.95em;
                }

                .submessage {
                    font-size: 0.85em;
                }
            }
        }
    }
}
/* critical:end */

@import '@truckdown/components-systems/Styles/variables.less';