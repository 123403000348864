/* critical:start */
/*body.v2 .mt-1 {
    margin-top: 0.5em;
}*/
.cmp-promotion {
  margin: 1em auto 1em auto;
  min-height: 150px;
  max-width: 1200px;
}
@media only screen and (max-width: 525px) {
  .cmp-promotion {
    min-height: 200px;
  }
}
.cmp-promotion .content {
  padding: 1em 0.5em;
  margin-top: 1em;
  background-color: #f2faf0;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border: 1.5px solid #28a745;
  border-radius: 4px;
}
.cmp-promotion .promo-listing {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 1em;
}
.cmp-promotion .promo-listing p {
  margin-bottom: 1em;
}
.cmp-promotion .promo-listing .submesssage {
  margin-bottom: 1.5em;
}
.cmp-promotion .promo-listing .address {
  align-self: start;
  text-align: right;
  color: #454545;
}
.cmp-promotion .promo-listing .message {
  height: fit-content;
}
.cmp-promotion .promo-listing .message a.more {
  display: inline-block;
  text-decoration: none;
  margin-left: 1em;
}
.cmp-promotion .promo-listing .message svg {
  color: #e35843;
  padding-right: 0.5em;
}
.cmp-promotion .promo-listing .message h3 {
  font-size: 1.25em;
  margin: 0 0 0.5em 0;
}
.cmp-promotion .promo-listing .message h4 {
  font-size: 1.2em;
  color: #e35843;
}
.cmp-promotion .promo-listing .message .td-btn {
  display: inline-block;
  background-color: #e35843;
  color: #fff;
}
@media only screen and (max-width: 525px) {
  .cmp-promotion .promo-listing {
    grid-template-columns: 1.7fr 1fr;
    grid-column-gap: 0.5em;
  }
  .cmp-promotion .promo-listing .address {
    font-size: 0.9em;
  }
  .cmp-promotion .promo-listing .address strong {
    font-size: 1em;
  }
  .cmp-promotion .promo-listing .message h3,
  .cmp-promotion .promo-listing .message h4,
  .cmp-promotion .promo-listing .message p {
    font-size: 0.95em;
  }
  .cmp-promotion .promo-listing .message .submessage {
    font-size: 0.85em;
  }
}
/* critical:end */
/* colors */
/* Fonts */
/* Display breakpoints */
/* most of the modern smartphones have dimesions >= 375px*/
/* older smartphones like iPhone 4, iPhone 5, etc.*/
/* Shadows */
/* Shapes */
