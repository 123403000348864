/* critical:start */

body .cmp-search-form {
    display: grid;
    background-color: inherit;
    grid-template-columns: 1fr;
    grid-row-gap: 0.5em;
    column-gap: 1.25rem;
    position: relative;
    min-height: 200px;
    width: 100%;
    align-content: center;

    select {
        padding: 0.65em 0.65em;
        margin: 0 0 0.25em 0;
    }

    @media only screen and (min-width: 800px) {
        grid-template-columns: 1.8fr 1fr;
        grid-template-rows: min-content min-content;

        .location {
            grid-column-start: 1;
            grid-column-end: 2;
        }

        .region {
            grid-column-start: 2;
            grid-column-end: 3;
        }

        .service {
            grid-column-start: 1;
            grid-column-end: 3;
        }

        .options {
            grid-column-start: 1;
            grid-column-end: 3;
        }

        .search-btn-wrapper {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    .td-autocomplete {
        .autocomplete-input {

            > .service-input, > .location-input, > .options-input {
                padding-left: 0.313rem !important;
                height: 25px;
            }

            > i, > .locations-icon {
                position: absolute;
                right: 0.5em;
                top: 25%;
                color: @dark-grey;
            }
        }
    }

    .service.td-autocomplete, .options.td-autocomplete {
        .td-selected-input {
            margin: 0.25em 0.75em 0.5em 0;
        }
    }
}

/* critical:end */

body .cmp-search-form {

    .td-autocomplete {
        .autocomplete-input {
            > .locations-icon:hover {
                color: @orange;
            }

            .td-selected-input .service-close {
                display: inline-block;
                border-left: 1px solid #e1e3e5;
                margin-left: 0.25em;
                padding: 0 0.25em;
            }
        }

        > .service-content {
            display: grid;
            grid-template-columns: 48% 47% 3%;
            grid-template-rows: 5% 95%;
            position: relative;
        }

        > .options-content {
            padding: 0;
            position: relative;

            > .filter {
                margin: 0.5em 0;
            }

            ul {
                display: grid;
            }
        }

        .close-content {
            right: 0.75em;
            padding-left: 0;
            position: absolute;
            z-index: 1000;
            padding-top: 0.5em;
            grid-column-start: 3;
            grid-row-start: 2;
            top: -7%;

            @media only screen and (min-width: 600px) {
                padding: 0.35em 0.5em 0 0;
                margin-left: 0;
            }
        }
    }
}

body.planrv .cmp-search-form {
    .td-autocomplete .autocomplete-input > .locations-icon:hover {
        color: @planrv-primary;
    }
}

@import '@truckdown/components-systems/Styles/variables.less';
