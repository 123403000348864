/* critical:start */

.images {
    position: relative;
    width: 100%;
    max-width: 390px;
    margin: 0 auto;
    padding-bottom: 1em;

    &.image-single {
        max-width: 600px;

        .carousel-container {
            overflow: visible;
        }
    }

    @media only screen and (min-width: 959px) and (max-width: 1200px) {
        max-width: 300px;
    }

    @media @md-down {
        max-width: 420px;
    }

    @media @sm-down {
        max-width: 270px;
    }

    @media @x-sm-only {
        max-width: 210px;
    }

    .carousel-indicators {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        span {
            width: 10px;
            height: 10px;
            border: none;
            border-radius: 50%;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;
            background-color: #bbb;
            transition: background-color 0.3s ease;

            &:hover, &.active {
                background-color: #555;
            }
        }
    }

    .carousel-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 75%;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: opacity 0.5s ease-in-out;
            opacity: 0;

            &.active {
                opacity: 1;
            }
        }
    }

    .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1.75rem;
        height: 1.75rem;
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    .arrow:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .arrow-left {
        left: -2rem;
    }

    .arrow-right {
        right: -2rem;
    }
}

/* critical:end */

.images {
    img.active {
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
    }
}

.arrow {
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.arrow:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.arrow-left {
    left: -2rem;
}

.arrow-right {
    right: -2rem;
}

@import '@truckdown/components-systems/Styles/variables.less';
