@default_color: #d4d4d4;
@tdpreferred_color: #f87d20;
@elite_color: #0a71a5;
@pro_color: #5ab7e8;
@branded_color: #8c3f1e;

@msp_color: #b20838;
@mspar_color: #b20838;

@planrv-branded: #75bbbf;
@planrv-elite: @planrv-primary;
@planrv-pro: #739558;
@planrv-primary: #eb5643;
@planrv-primary-active: #ec442e;
@planrv-light: #fff7f7;

/* critical:start */

.listing_header(@header_color, @header_bg_color, @header_border_color, @header_icon_color ) {
    .header {
        background-color: @header_bg_color;
        border: solid 1px @header_border_color;

        a, svg, span.title {
            color: @header_color;
        }

        a:hover, svg:hover {
            color: @header_color;
            font-weight: bold;
        }

        .icon.preferred {
            color: @header_icon_color;
        }
    }
}

.listing_body( @highlights_icon_color ) {
    .body {
        .highlights svg {
            color: @highlights_icon_color;
        }
    }
}

.listing_footer( @footer_button_color, @footer_button_bgcolor, @footer_button_bordercolor, @footer_activebutton_color, @footer_activebutton_bgcolor, @footer_activebutton_bordercolor) {
    .footer {
        button {
            color: @footer_button_color;
            border: 0.094em solid @footer_button_bordercolor;
            background-color: @footer_button_bgcolor;

            &:hover {
                color: @footer_activebutton_color;
                background-color: @footer_activebutton_bgcolor;
                border: 0.094em solid @footer_activebutton_bordercolor;
                filter: brightness(1.1);
            }

            &:active, &.active {
                color: @footer_activebutton_color;
                background-color: @footer_activebutton_bgcolor;
                border: 0.094em solid @footer_activebutton_bordercolor;
            }
        }
    }
}

.listing-details(@icons_color) {
    .td-list li svg {
        color: @icons_color;
    }
}

.logo-styles (@div-dimensions, @img-dimensions) {
    div.logo {
        width: @div-dimensions;
        height: @div-dimensions;

        a img {
            width: @img-dimensions;
            height: auto;
            max-height: @img-dimensions;
        }
    }
}

.cmp-listing {
    width: 100%;
    margin: 1em 0;
    background-color: #fff;
    text-align: left;

    .promo-banner {
        color: #fff;
        background-color: #e35843;
        padding: 0.35em 0.5em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5em;
        flex-wrap: wrap;

        h6 {
            color: #fff;
            margin: 0;
        }

        button.slim {
            color: #e35843;
            padding: 0.25em 0.5em;
        }
    }

    .header {
        display: grid;
        grid-template-columns: auto max-content min-content;
        align-items: center;
        grid-column-gap: 0.5em;
        padding: 0.5em;
        position: relative;

        a.title {
            display: flex;

            svg {
                margin-right: 0.35em;
            }
        }

        span.name {
            margin-right: 0.5em;
            white-space: nowrap;
        }

        .icon.type {
            width: 1em;
            height: 1em;
            min-width: 1em;
            display: inline-block;
            margin-right: 0.35em;
        }
    }

    .body {
        padding: 0.5em;
        grid-row-gap: 0.75em;
        display: grid;
        grid-column-gap: 0.5em;
        grid-template-columns: 100%;
        grid-template-areas:
            'address' 'links' 'phonenumbers' 'tagline' 'highlights';
        align-items: start;

        .logo {
            grid-area: logo;
        }

        .address {
            grid-area: address;
            line-height: 1.5em;
        }

        .cmp-listing-links {
            grid-area: links;
            display: flex;
            flex-wrap: wrap;
            column-gap: 0.75em;
            row-gap: 0.75em;
            margin-right: 1em;

            a.td-badge {
                padding: 0.35em 0.75em;
            }
        }

        .phone-numbers {
            grid-area: phonenumbers;
            line-height: 1.25em;
            justify-self: end;

            .labels {
                padding-right: 0.35em;
            }

            .numbers {
                display: inline-block;
            }
        }

        .tagline {
            grid-area: tagline;
            padding-top: 0.5em;
        }

        .highlights {
            grid-area: highlights;
            display: flex;
            flex-wrap: wrap;
            column-gap: 1.75em;
            line-height: 1.5em;

            .highlight {
                svg {
                    margin-right: 0.25em;
                }
            }
        }
    }


    @media screen and (min-width: 960px) {
        .body {
            grid-template-columns: calc(50% + 58px) auto calc(50% - 232px);
            grid-column-gap: 0;
            grid-template-areas:
                'address blank1 phonenumbers' 'links links blank2' 'tagline tagline tagline' 'highlights highlights highlights';

            .links {
                margin-top: 0.25em;
            }
        }
    }
    //default
    //@highlights_icon_color
    .listing_body(@dark-grey);

    .footer {
        display: grid;
        justify-content: space-between;
        align-items: center;
        grid-template-columns: repeat(5, auto);
        grid-column-gap: 0.35em;
        grid-template-rows: auto auto;
        padding: 0.5em;

        button {
            font-size: 0.88em;
            padding: 0.5em 0.9em;
            text-transform: uppercase;
        }
    }
    //default
    //@footer_button_color, @footer_button_bgcolor, @footer_button_bordercolor, @footer_activebutton_color, @footer_activebutton_bgcolor, @footer_activebutton_bordercolor
    .listing_footer(@gray, #fff, @gray, #fff, @dark-grey, @dark-grey);

    @media @lg-down {
        .footer button {
            font-size: 0.85em;
        }
    }

    @media @md-down {
        .body {
            .phone-numbers {
                justify-self: start;

                .labels {
                    text-align: left;
                }

                button {
                    font-size: 0.9em;
                    padding: 0.5em 1em;
                }
            }
        }

        .footer button {
            padding: 0.5em;
            font-size: 0.8em;
        }
    }

    @media only screen and (max-width: 750px) {
        .footer button {
            display: flex;
            flex-direction: column;
            grid-row-gap: 0.35em;
            line-height: 1.1em;
            font-size: 0.82em;
            text-transform: capitalize;
            padding: 0.45em;
            height: 52px;
        }
    }

    @media only screen and (max-width: 560px) {
        .header .title {
            max-width: 260px;
            overflow-y: auto;
            text-overflow: clip;
        }

        .body .cmp-listing-links {
            font-size: 0.83em;
        }

        .footer button {
            height: 62px;
            line-height: 0.93em;
            font-size: 0.76em;
            max-width: 58px;
            text-transform: none;
        }
    }

    @media @x-sm-only {
        .footer button {
            font-size: 0.7em;
            max-width: 52px;
            padding: 0.3em;
        }
    }

    &.stock {
        .body {
            grid-template-columns: 100%;

            @media screen and (min-width: 380px) {
                .links, .address, .phone-numbers {
                    margin-left: 0.5em;
                }

                .phone-numbers .numbers {
                    padding-left: 0;
                }
            }
        }

        @media screen and (min-width: 960px) {
            .body {
                grid-template-columns: calc(50% + 58px) auto calc(50% - 232px);
                grid-column-gap: 0;
                grid-template-areas:
                    'address blank1 phonenumbers' 'links links blank2' 'tagline tagline tagline' 'highlights highlights highlights';

                .links {
                    margin-top: 0.25em;
                }
            }
        }
    }

    &.premium {
        .body {
            grid-template-columns: 100%;
            grid-template-areas:
                'logo' 'address' 'links' 'phonenumbers' 'tagline' 'highlights';
            .logo-styles (110px, 108px);
        }

        @media screen and (min-width: 380px) {
            .body {
                grid-template-columns: 110px calc(100% - 110px);
                grid-template-areas:
                    'logo address' 'logo links' 'logo phonenumbers' 'tagline tagline' 'highlights highlights';
            }
        }

        @media screen and (min-width: 960px) {
            .body {
                grid-template-columns: 210px calc(50% - 132px) auto calc(50% - 232px);
                grid-template-areas:
                    'logo address blank1 phonenumbers' 'logo links links blank2' 'logo tagline tagline tagline' 'logo highlights highlights highlights';
                .logo-styles (178px, 176px);
            }
        }
    }

    .footer {

        .btn-double {
            display: inline-flex;
        }

        button.td-btn {
            .icon {
                position: relative;
                margin-right: 0.75em;

                & .td-circle-badge {
                    border-radius: 1.8em;
                    padding: 0.25em 0.56em;
                    font-size: 0.6em;
                    position: absolute;
                    top: -5px;
                    margin-left: 0.15em;
                }
            }
        }
    }

    .details {
        padding: 1em 0.75em;
        display: grid;
        grid-template-columns: 55% 43%;
        column-gap: 1em;

        .description, .services, .dealers, .column-right, .hours-container {
            margin-bottom: 1em;
        }

        @media @md-down {
            grid-template-columns: 100%;

            li.service .item {
                padding: 0.25em 0;
            }
        }

        h4, h3 {
            font-size: 1.15em;
            padding-bottom: 0.5em;
            text-transform: uppercase;
        }

        .td-list svg {
            margin-right: 0.25em;
        }

        li.service {
            line-height: 1.35em;

            ul {
                margin: 0.1em 0 0 2.5em;
            }
        }

        .hours-payment {
            @media only screen and (min-width: 720px) and (max-width: 959px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 1em;
            }

            @media only screen and (max-width: 719px) {
                grid-template-columns: 1fr;
            }
        }

        .hours-container {
            .td-badge {
                display: block;
                margin: 0 0 0.75em 0;
            }

            .time {
                padding: 0.5em 0;
                display: flex;
                column-gap: 0.75em;

                .message {
                    font-size: 0.85em;
                }
            }
        }

        .payment_types img {
            margin-right: 0.5em;
        }
    }
}

/* critical:end */
.a.td-btn {
    display: inline-block;
}

.cmp-hours-status {
    span.icon {
        &.closed svg {
            color: @error;
        }

        &.open svg {
            color: @green;
        }
    }
}

.cmp-listing {
    color: @dark-grey;
    box-shadow: @card-shadow;
    border-radius: @border-radius;
    .listing_header(@dark-grey, #c5c5c5, #c5c5c5, #f87d20);

    .header {
        border-top-right-radius: @border-radius;
        border-top-left-radius: @border-radius;

        a, svg {
            cursor: pointer;
        }
    }

    .body {
        .cmp-listing-links {
            a {
                box-shadow: 0 0 5px rgba(23,24,24,.05), 0 1px 2px rgba(0,0,0,.15);

                &:hover {
                    background-color: #dbd9d9;
                }
            }
        }

        .phone-numbers {

            .labels {
                font-weight: bold;
                display: inline-block;
                text-align: right;
            }
        }

        .tagline {
            font-style: italic;
        }
    }

    &.preferred {
        //#f87d20
        //@header_color, @header_bg_color, @header_border_color, @header_icon_color
        .listing_header(#fff, @tdpreferred_color, @tdpreferred_color, #5ab7e8);
        //@highlights_icon_color
        .listing_body(@tdpreferred_color);
        //@footer_button_color, @footer_button_bgcolor, @footer_button_bordercolor, @footer_activebutton_color, @footer_activebutton_bgcolor, @footer_activebutton_bordercolor
        .listing_footer(@tdpreferred_color, #fff, @tdpreferred_color, #fff, @tdpreferred_color, @tdpreferred_color);
        //@icons_color
        .listing-details(@tdpreferred_color);

        &.promo {
            border: 1px solid @tdpreferred_color;
        }
    }

    &.elite {
        //@header_color, @header_bg_color, @header_border_color, @header_icon_color
        .listing_header(#fff, @elite_color, @elite_color, #f87d20);
        //@highlights_icon_color
        .listing_body(@dark-blue);
        //@footer_button_color, @footer_button_bgcolor, @footer_button_bordercolor, @footer_activebutton_color, @footer_activebutton_bgcolor, @footer_activebutton_bordercolor
        .listing_footer(@dark-blue, #f7f7f7, @dark-blue, #fff, @dark-blue, @dark-blue);
        //@icons_color
        .listing-details(@dark-blue);

        &.promo {
            border: 1px solid @dark-blue;
            background-color: @background-blue;
        }
    }

    &.pro {
        //@header_color, @header_bg_color, @header_border_color, @header_icon_color
        .listing_header(#fff, @pro_color, @pro_color, #f87d20);
        //@highlights_icon_color
        .listing_body(@blue);
        //@footer_button_color, @footer_button_bgcolor, @footer_button_bordercolor, @footer_activebutton_color, @footer_activebutton_bgcolor, @footer_activebutton_bordercolor
        .listing_footer(#4fa1cc, #f7f7f7, #4fa1cc, #fff, #55abd8, @blue);
        //@icons_color
        .listing-details(@blue);

        &.promo {
            border: 1px solid @blue;
            background-color: @background-blue;
        }
    }

    &.branded {
        //@header_color, @header_bg_color, @header_border_color, @header_hover_color, @header_icon_color
        .listing_header(#fff, @branded_color, @branded_color, #f87d20);
        //@highlights_icon_color
        .listing_body(@branded_color);
        //@footer_button_color, @footer_button_bgcolor, @footer_button_bordercolor, @footer_activebutton_color, @footer_activebutton_bgcolor, @footer_activebutton_bordercolor
        .listing_footer(@branded_color, #f7f7f7, @branded_color, #fff, @branded_color, @branded_color);
        //@icons_color
        .listing-details(@branded_color);

        &.promo {
            border: 1px solid @branded_color;
            background-color: @background-orange;
        }
    }

    &.msp {
        //@header_color, @header_bg_color, @header_border_color, @header_icon_color
        .listing_header(#fff, @msp_color, @msp_color, #f87d20);
        //@highlights_icon_color
        .listing_body(@msp_color);
        //@footer_button_color, @footer_button_bgcolor, @footer_button_bordercolor, @footer_activebutton_color, @footer_activebutton_bgcolor, @footer_activebutton_bordercolor
        .listing_footer(@msp_color, #f7f7f7, @msp_color, #fff, @msp_color, @msp_color);
        //@icons_color
        .listing-details(@msp_color);
    }

    &.mspar {
        //@header_color, @header_bg_color, @header_border_color, @header_icon_color
        .listing_header(#fff, @mspar_color, @mspar_color, #f87d20);
        //@highlights_icon_color
        .listing_body(@mspar_color);
        //@footer_button_color, @footer_button_bgcolor, @footer_button_bordercolor, @footer_activebutton_color, @footer_activebutton_bgcolor, @footer_activebutton_bordercolor
        .listing_footer(@mspar_color, #fff, @mspar_color, #fff, @mspar_color, @mspar_color);
        //@icons_color
        .listing-details(@mspar_color);
    }

    &.premium {
        .body {
            .phone-numbers {
                button {
                    color: #fff;
                    background-color: @orange;
                    border: none;

                    &:hover {
                        background-color: @orange-active;
                    }

                    &:active {
                        background-color: @orange-active;
                    }
                }
            }
        }
    }

    .footer {

        button {
            font-weight: bold;
        }

        .btn-double {
            .favorite-btn {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                border-right: none;
                cursor: pointer;
            }

            .avoid-btn {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                cursor: pointer;
            }
        }
    }

    .footer {
        button.td-btn {

            &.disabled,
            &:disabled,
            &[disabled] {
                border: 1px solid @border-color;
                background-color: #f1f1f1;
                color: @gray;
                opacity: 0.7;

                &:active, &.active, &:focus {
                    color: @dark-grey;
                    border: 1px solid @dark-grey;
                    background-color: #f1f1f1;

                    &:hover {
                        background-color: #f1f1f1;
                    }
                }

                &:hover {
                    color: @dark-grey;
                    border: 1px solid @dark-grey;
                }
            }

            .icon {

                & .td-circle-badge {
                    background-color: #f87d20;
                    color: #fff;
                }
            }
        }
    }

    .details {
        background-color: #fff;

        h4, h3 {
            text-transform: uppercase;
            border-bottom: 1px solid @divider;
        }

        li.service {
            ul {
                list-style-type: circle;
                align-self: start;
            }
        }

        .hours-container {
            .time {
                text-align: right;
                justify-content: space-between;

                &:not(:last-child) {
                    border-bottom: 1px solid @divider;
                }

                .message {
                    font-style: italic;
                    color: @text-subdued;
                    padding-bottom: 0.1em;

                    &.td-text-danger {
                        color: @error;
                    }
                }

                .day {
                    text-align: left;
                }

                &.closed {
                    color: @error;
                }
            }
        }
    }

    .captcha, .warning {
        color: #c55806; //warning
        width: 300px;
        display: inline-block;
        padding-bottom: 0.5em;
    }
    /* Transitions on expand */

    .expand-enter-active {
        transition: opacity 0.5s ease;
    }

    .expand-enter-from {
        opacity: 0;
    }
    /* End of transitions on expand */

    .listing-map {
        height: 300px;
    }

    .data {
        background-color: #e7e7e7;

        > div {
            min-height: 320px;
        }

        .subheading {
            font-style: italic;
        }

        .action-wrapper {
            padding: 1.25em 0.5em;
        }

        .services-container {
            padding: 1em;

            .service-list {
                list-style-type: none;
                display: grid;
                justify-items: baseline;
                grid-template-columns: 1fr 1fr;
                padding: 0;

                .item ul {
                    padding-left: 1.5em;
                }
            }
        }

        .inputs-container {
            max-width: 600px;
            padding: 1em 0.5em;

            @media @md-down {
                max-width: 100%;
            }

            margin: 0 auto;
            display: grid;
            grid-template-columns: 1fr;

            input, textarea {
                margin-top: 0.5em;

                &::placeholder {
                    opacity: .8;
                    font-size: .95em;
                }
            }
        }

        .form-buttons {
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 1.75em 0 1em 0;
            width: 100%;

            @media @md-down {
                padding: 1.75em 0em 1em 0em;
                max-width: 100%;
            }

            &.pb-0 {
                padding-bottom: 0;
            }
        }
    }
}

.planrv .cmp-listing {

    .footer button.action .icon .badge {
        background-color: @planrv-primary;
    }

    &.premium {
        .phone-numbers {
            button {
                color: #fff;
                background-color: @planrv-primary;
                border: none;

                &:hover, &:active {
                    background-color: @planrv-primary-active;
                }
            }
        }
    }

    &.elite {
        //@header_color, @header_bg_color, @header_border_color, @header_icon_color, @header_name_width
        .listing_header(#fff, @planrv-elite, @planrv-elite, @planrv-elite);
        //@highlights_icon_color
        .listing_body(@planrv-elite);
        //@footer_button_color, @footer_button_bgcolor, @footer_button_bordercolor, @footer_activebutton_color, @footer_activebutton_bgcolor, @footer_activebutton_bordercolor
        .listing_footer(@planrv-elite, #f7f7f7, @planrv-elite, #fff, @planrv-elite, @planrv-elite);
        //@icons_color
        .listing-details(@planrv-elite);
    }

    &.pro {
        //@header_color, @header_bg_color, @header_border_color, @header_icon_color, @header_name_width
        .listing_header(#fff, @planrv-pro, @planrv-pro, @planrv-pro);
        //@highlights_icon_color
        .listing_body(@planrv-pro);
        //@footer_button_color, @footer_button_bgcolor, @footer_button_bordercolor, @footer_activebutton_color, @footer_activebutton_bgcolor, @footer_activebutton_bordercolor
        .listing_footer(@planrv-pro, #f7f7f7, @planrv-pro, #fff, @planrv-pro, @planrv-pro);
        //@icons_color
        .listing-details(@planrv-pro);
    }

    &.branded {
        //@header_color, @header_bg_color, @header_border_color, @header_hover_color, @header_icon_color, @header_name_width
        .listing_header(#fff, @planrv-branded, @planrv-branded, @planrv-branded);
        //@highlights_icon_color
        .listing_body(@planrv-branded);
        //@footer_button_color, @footer_button_bgcolor, @footer_button_bordercolor, @footer_activebutton_color, @footer_activebutton_bgcolor, @footer_activebutton_bordercolor
        .listing_footer(@planrv-branded, #f7f7f7, @planrv-branded, #fff, @planrv-branded, @planrv-branded);
        //@icons_color
        .listing-details(@planrv-branded);
    }
}


@import '@truckdown/components-systems/Styles/variables.less';