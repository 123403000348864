/* critical:start */
body .cmp-search-form {
  display: grid;
  background-color: inherit;
  grid-template-columns: 1fr;
  grid-row-gap: 0.5em;
  column-gap: 1.25rem;
  position: relative;
  min-height: 200px;
  width: 100%;
  align-content: center;
}
body .cmp-search-form select {
  padding: 0.65em 0.65em;
  margin: 0 0 0.25em 0;
}
@media only screen and (min-width: 800px) {
  body .cmp-search-form {
    grid-template-columns: 1.8fr 1fr;
    grid-template-rows: min-content min-content;
  }
  body .cmp-search-form .location {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  body .cmp-search-form .region {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  body .cmp-search-form .service {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  body .cmp-search-form .options {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  body .cmp-search-form .search-btn-wrapper {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
body .cmp-search-form .td-autocomplete .autocomplete-input > .service-input,
body .cmp-search-form .td-autocomplete .autocomplete-input > .location-input,
body .cmp-search-form .td-autocomplete .autocomplete-input > .options-input {
  padding-left: 0.313rem !important;
  height: 25px;
}
body .cmp-search-form .td-autocomplete .autocomplete-input > i,
body .cmp-search-form .td-autocomplete .autocomplete-input > .locations-icon {
  position: absolute;
  right: 0.5em;
  top: 25%;
  color: #454545;
}
body .cmp-search-form .service.td-autocomplete .td-selected-input,
body .cmp-search-form .options.td-autocomplete .td-selected-input {
  margin: 0.25em 0.75em 0.5em 0;
}
/* critical:end */
body .cmp-search-form .td-autocomplete .autocomplete-input > .locations-icon:hover {
  color: #f87d20;
}
body .cmp-search-form .td-autocomplete .autocomplete-input .td-selected-input .service-close {
  display: inline-block;
  border-left: 1px solid #e1e3e5;
  margin-left: 0.25em;
  padding: 0 0.25em;
}
body .cmp-search-form .td-autocomplete > .service-content {
  display: grid;
  grid-template-columns: 48% 47% 3%;
  grid-template-rows: 5% 95%;
  position: relative;
}
body .cmp-search-form .td-autocomplete > .options-content {
  padding: 0;
  position: relative;
}
body .cmp-search-form .td-autocomplete > .options-content > .filter {
  margin: 0.5em 0;
}
body .cmp-search-form .td-autocomplete > .options-content ul {
  display: grid;
}
body .cmp-search-form .td-autocomplete .close-content {
  right: 0.75em;
  padding-left: 0;
  position: absolute;
  z-index: 1000;
  padding-top: 0.5em;
  grid-column-start: 3;
  grid-row-start: 2;
  top: -7%;
}
@media only screen and (min-width: 600px) {
  body .cmp-search-form .td-autocomplete .close-content {
    padding: 0.35em 0.5em 0 0;
    margin-left: 0;
  }
}
body.planrv .cmp-search-form .td-autocomplete .autocomplete-input > .locations-icon:hover {
  color: #eb5643;
}
/* colors */
/* Fonts */
/* Display breakpoints */
/* most of the modern smartphones have dimesions >= 375px*/
/* older smartphones like iPhone 4, iPhone 5, etc.*/
/* Shadows */
/* Shapes */
